import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// utils
import { formatNumber } from "../../../utils";

// css
import * as styles from "./PlanCards.module.css";

const PlanCards = props => {
  return (
    <div className={styles.planCard__container}>
      <div className="container">
        <div className="row">
          {props.selectedTreatmentData.plans.map(data => {
            return (
              <div className="col-12 col-md-4 col-lg-4">
                <div
                  className={
                    props.selectedTreatmentData.treatmentTypeValue === "wbdt_diabetes"
                      ? styles.planCard__diabetesCard
                      : styles.planCard__card
                  }
                >
                  <div className={styles.planCard__topTxtDiv}>
                    <p>{data.text}</p>
                    {data.planType === "annual_plan" ? (
                      <div className={styles.planCard__recomend}>
                        <h3>RECOMMENDED</h3>
                      </div>
                    ) : null}
                  </div>

                  {/* Different paras for annual plan, installment plan and quarterly plan */}
                  {data.planType === "annual_plan" ? (
                    <div className={styles.planCard__amountParaDiv}>
                      <h5>
                        {formatNumber(Math.round(data.planPrice / 12))}
                        <span>/ month</span>
                      </h5>
                      <p>
                        One time payment of{" "}
                        <span>{formatNumber(data.planPrice)}</span> per year
                      </p>
                    </div>
                  ) : data.planType === "installment_plan" ? (
                    <div className={styles.planCard__amountParaDiv}>
                      <h5>
                        {formatNumber(Math.round(data.planPrice / 6))}
                        <span>/ month</span>
                      </h5>
                      <p>
                        Instalment of{" "}
                        <span>{formatNumber(data.planPrice)}</span> every 6
                        months. Total <span>2</span> instalments in a year
                      </p>
                    </div>
                  ) : (
                    <div className={styles.planCard__amountParaDiv}>
                      <h5>
                        {formatNumber(Math.round(data.planPrice / 3))}
                        <span>/ month</span>
                      </h5>
                      <p>
                        Instalment of{" "}
                        <span>{formatNumber(data.planPrice)}</span> per quarter.
                        Total <span>4</span> instalments in a year
                      </p>
                    </div>
                  )}

                  {/* <div className={styles.planCard__includeDiv}>
                    <p>INCLUDED</p>
                    {data.includedItems.map(includeItem => {
                      return (
                        <ul>
                          <li>{includeItem}</li>
                        </ul>
                      );
                    })}
                  </div> */}
                  {/* <div className={styles.planCard__excludeDiv}>
                    <p>EXCLUDED</p>
                    {data.excludedItems.map(excludeItem => {
                      return (
                        <ul>
                          <li>{excludeItem}</li>
                        </ul>
                      );
                    })}
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-12">
            <div>
              <div className={styles.planCard__ketonePara}>
                {/* <h3>
                  * Ketone Meter is required only for T2 Diabetic Patients
                  taking insulin
                </h3> */}
                <h3>
                  * List of smart watch compatible with the Program refer below
                </h3>
              </div>
              <div className={`row ${styles.planCard__ketonePara__fitbit}`}>
                <p className="col-12 col-md-2">Fitbit: </p>
                <p className="col-12 col-md-7">
                  Sense, Versa 3, Versa 2, Versa 4, Versa, Charge 6, Charge 5, Charge 4, Charge 3,
                  Charge 2, Inspire 2, and Inspire HR
                </p>
                <p className="col-12 col-md-3"></p>
              </div>
              <div className={`row ${styles.planCard__ketonePara__fitbit}`}>
                <p className="col-12 col-md-2">Garmin:</p>
                <p className="col-12 col-md-4">Vivosmart® 4, Vivosmart® 5, Vivoactive® 4</p>
                <p className="col-12 col-md-6"></p>
              </div>
              <div className={`row ${styles.planCard__ketonePara__fitbit}`}>
                <p className="col-12 col-md-2">Apple Watch:</p>
                <p className="col-12 col-md-4">Apple Watch Series 3 and above</p>
                <p className="col-12 col-md-6"></p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className={`text-center ${styles.planCard__cta}`}>
              <AnchorLink to="/#getInTouch" className="navLink">
                <p>Get In Touch</p>
              </AnchorLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCards;
