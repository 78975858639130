// extracted by mini-css-extract-plugin
export var planCard__amountParaDiv = "PlanCards-module--planCard__amountParaDiv--d6w6y";
export var planCard__card = "PlanCards-module--planCard__card--GouIt";
export var planCard__container = "PlanCards-module--planCard__container--wJpiv";
export var planCard__cta = "PlanCards-module--planCard__cta--Hh3dP";
export var planCard__diabetesCard = "PlanCards-module--planCard__diabetesCard--oylv7";
export var planCard__excludeDiv = "PlanCards-module--planCard__excludeDiv--MOeBq";
export var planCard__includeDiv = "PlanCards-module--planCard__includeDiv--r0c+5";
export var planCard__ketonePara = "PlanCards-module--planCard__ketonePara--7zGnb";
export var planCard__ketonePara__fitbit = "PlanCards-module--planCard__ketonePara__fitbit--OLphn";
export var planCard__recomend = "PlanCards-module--planCard__recomend--ptCCy";
export var planCard__topTxtDiv = "PlanCards-module--planCard__topTxtDiv--m9DPs";