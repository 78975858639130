import React from "react";
import { Link } from "gatsby";

// styles
import * as styles from "./FooterV1.module.css";

const NewFooter = props => {
  return (
    <div
      className={styles.newFooter__container}
      style={{ background: props.bgColor }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <Link
              to="/terms"
              className={`navLink ${styles.newFooter__link}`}
              style={{ color: props.textColor }}
            >
              Terms Of Use
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
