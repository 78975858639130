import React, { useState } from "react";

// css
import * as styles from "./ToggleButton.module.css";

const ToggleButton = props => {
  const [toggleState, setToggleState] = useState(
    props.treatmentPlans[0].treatmentTypeValue
  );

  const updateToggleButtonValue = e => {
    props.handleToggleButton(e.target.value);
    setToggleState(e.target.value);
  };

  return (
    <div className={styles.toggleButton__container}>
      <div className="container">
        <div className="row">
          <div className={`col-12 ${styles.toggleBtn__hdngSec}`}>
            <h3>Select Precision Treatment</h3>
          </div>
        </div>
        <div className={`row ${styles.toggleBtn__ctaSec}`}>
          {props.treatmentPlans.map((treatment, index) => {
            return (
              <button
                onClick={updateToggleButtonValue}
                value={treatment.treatmentTypeValue}
                key={index}
                className={
                  toggleState &&
                  toggleState === `${treatment.treatmentTypeValue}`
                    ? `col-xs-6 col-sm-6 col-md-12 col-lg-12 ${styles.toggleBtn__refer}`
                    : `col-xs-6 col-sm-6 col-md-12 col-lg-12 ${styles.toggleBtn__track}`
                }
              >
                {treatment.treatmentType}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ToggleButton;
