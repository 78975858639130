import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// css
import * as styles from "./ProgramIncludes.module.css";

const ProgramIncludes = () => {
  return (
    <div className={styles.programInclude__container}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={styles.programInclude__hdng}>
              <h3>Program Includes</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-4">
            <div>
              {/* Image */}
              <div className={styles.programInclude__img}>
                <StaticImage
                  src="../../../images/bookPack/pre_enrol_img.png"
                  alt="wbdt_image"
                  placeholder="blurred"
                  loading="lazy"
                />
              </div>
              {/* Para */}
              <div className={styles.programInclude__para}>
                <p>
                  <span>Mobile App</span> from Twin Health with health insights
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-4">
            <div>
              {/* Image */}
              <div className={styles.programInclude__img}>
                <StaticImage
                  src="../../../images/bookPack/wbdt_pricing_2.png"
                  alt="wbdt_image"
                  placeholder="blurred"
                  loading="lazy"
                />
              </div>
              {/* Para */}
              <div className={styles.programInclude__para}>
                <p>
                  Whole Body <span>Digital Twin</span> Technology (by AI & ML)
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-4">
            <div>
              {/* Image */}
              <div className={styles.programInclude__img}>
                <StaticImage
                  src="../../../images/bookPack/wbdt_pricing_3.png"
                  alt="wbdt_image"
                  placeholder="blurred"
                  loading="lazy"
                />
              </div>
              {/* Para */}
              <div className={styles.programInclude__para}>
                <p>
                  Care Team Services: <span>(Health Coach & Doctors)</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramIncludes;
